import React, { useState } from 'react'
import palette from 'core-system/Themes/palette'
import GridCard from 'employee-platform/shared/components/GridCard'
import { GridContainer } from 'employee-platform/shared/styles/Grid'
import AccountCircleIcon from 'core-system/Icons/Accounts/AccountCircle'
import EditIcon from 'core-system/Icons/Actions/Edit'
import CloseIcon from 'core-system/Icons/Actions/Close'
import CreditCardIcon from 'core-system/Icons/Accounts/CreditCard'
import ProfileSettings from './components/ProfileSettings'
import { screenSizes } from 'shared/RandomUtils'
import useResizeListener from 'shared/Hooks/useResizeListener'
import { Footer } from 'core-system/Program'
import Button from 'core-system/Button'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/config/store'
import { useDispatch } from 'react-redux'
import WalletDetails from './components/WalletDetails'
import { employeePlatformActions } from 'redux/employeePlatform/employeePlatformSlice'
import UploadReceiptDropbox from './components/UploadReceiptDropbox'
import AlertBanner from 'core-system/AlertBanner'
export interface EditableProfileFields {
  homeAddress: string | null
  unitNumber: string | null
  postcode: string | null
  commutePreference: string | null
}
import { useGateValue } from '@statsig/react-bindings'

const FooterContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};
`

const FooterButtonsContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 1rem;
  ${(props) =>
    props.isMobile
      ? `width: 100%;
        flex-direction: column;`
      : ''}
`

const getClickableIcon = (editing: boolean) => {
  return editing ? (
    <CloseIcon color={palette.text.secondary} />
  ) : (
    <EditIcon color={palette.text.secondary} />
  )
}

const ProfileSettingsView = React.memo(() => {
  const { profileData } = useSelector(
    (state: AppState) => state.employeePlatform
  )

  const dispatch = useDispatch()

  const [profileEditing, setProfileEditing] = useState(false)
  const defaultEditingState = {
    homeAddress: profileData?.homeAddress ?? null,
    unitNumber: profileData?.unitNumber ?? '',
    postcode: profileData?.postcode ?? '',
    commutePreference: profileData?.currentTransport ?? null,
  }
  const [profileFields, setProfileFields] = useState(defaultEditingState)
  const [selectedAddress, setSelectedAddress] = useState(
    profileData?.homeAddress ?? null
  )
  const [addressHasError, setAddressHasError] = useState(false)
  const [showAlertBanner, setShowAlertBanner] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= screenSizes.mobile
  )
  const handleResize = React.useCallback(
    (width: number) => {
      setIsMobile(width <= screenSizes.mobile)
    },
    [setIsMobile]
  )
  useResizeListener(() => handleResize(window.innerWidth), [])

  const handleCancelEdit = () => {
    setProfileEditing(false)
    setAddressHasError(false)
    setProfileFields(defaultEditingState)
    setSelectedAddress(profileData?.homeAddress ?? null)
  }

  const handleUpdateProfileSettings = () => {
    // Check that the user has selected an address from the suggestions by comparing the address in profileFields
    // with selectedAddress. If they don't match then that means a user has not selected an address from the suggestions
    if (
      selectedAddress !== profileFields.homeAddress ||
      !selectedAddress ||
      !profileFields.homeAddress
    ) {
      setAddressHasError(true)
    } else {
      // Form the data for the put request
      const updateRequestData = {
        ...profileData,
        homeAddress: profileFields.homeAddress,
        unitNumber:
          profileFields.unitNumber === '' ? null : profileFields.unitNumber,
        postcode: profileFields.postcode,
        currentTransport: profileFields.commutePreference,
      }

      // Make the put request
      dispatch(employeePlatformActions.updateProfileData(updateRequestData))
      handleCancelEdit()
    }
  }

  const updateButtonDisabled =
    !profileFields.homeAddress || !profileFields.commutePreference

  return (
    <>
      {showAlertBanner && (
        <AlertBanner
          variant='success'
          message={`Upload successful!`}
          closeFn={() => setShowAlertBanner(false)}
          marginBottom='1rem'
        />
      )}
      {showErrorBanner && (
        <AlertBanner
          variant='redAlert'
          message={`Upload failed!`}
          closeFn={() => setShowErrorBanner(false)}
          marginBottom='1rem'
        />
      )}
      <GridContainer numColumns={2} fitContentHeight>
        <GridContainer numColumns={1} fitContentHeight>
          <GridCard
            headerTitle='Profile Settings'
            headerIcon={<AccountCircleIcon />}
            clickable={profileData?.openSignup}
            clickableIcon={getClickableIcon(profileEditing)}
            onClick={() =>
              profileEditing ? handleCancelEdit() : setProfileEditing(true)
            }
            tooltipText={profileEditing ? 'Cancel Edit' : 'Edit Profile'}
          >
            <ProfileSettings
              isMobile={isMobile}
              editing={profileEditing}
              editableFields={profileFields}
              setEditableFields={setProfileFields}
              addressHasError={addressHasError}
              setSelectedAddress={setSelectedAddress}
            />
          </GridCard>
        </GridContainer>
        <GridContainer numColumns={1} fitContentHeight>
          <GridCard
            headerTitle='Wallet Details'
            headerIcon={<CreditCardIcon />}
            // clickable={!profileEditing}
            // clickableIcon={getClickableIcon(billingEditing)}
            // onClick={() => setBillingEditing(!billingEditing)}
          >
            <WalletDetails isMobile={isMobile} />
          </GridCard>
        </GridContainer>
        {useGateValue('invoice_ocr') && (
          <GridContainer numColumns={1} fitContentHeight>
            <GridCard headerTitle='Upload Receipt'>
              <UploadReceiptDropbox
                onUploadSuccess={() => {
                  setShowAlertBanner(true)
                }}
                onUploadError={() => {
                  setShowErrorBanner(true)
                }}
              />
            </GridCard>
          </GridContainer>
        )}
        {profileEditing ? (
          <Footer>
            <FooterContainer isMobile={isMobile}>
              <FooterButtonsContainer isMobile={isMobile}>
                {!isMobile ? (
                  <Button variant='tertiary' onClick={() => handleCancelEdit()}>
                    Cancel
                  </Button>
                ) : null}
                <Button
                  onClick={() => handleUpdateProfileSettings()}
                  disabled={updateButtonDisabled}
                >
                  Update Profile Settings
                </Button>
                {isMobile ? (
                  <Button
                    variant='secondary'
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </Button>
                ) : null}
              </FooterButtonsContainer>
            </FooterContainer>
          </Footer>
        ) : null}
      </GridContainer>
    </>
  )
})

// Helps identify component in React error logs
if (process.env.NODE_ENV !== 'production') {
  ProfileSettingsView.displayName = 'ProfileSettingsView'
}

export default ProfileSettingsView
